import { Component, OnInit } from '@angular/core';
import {ModalService} from '../../../services/modal.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  text: string | null = null;

  loadingText$ = this.modalService.loadingText$;
  constructor(private modalService: ModalService) {
    this.loadingText$.subscribe((text) => {
      if (text){
        this.text = text;
      }
    });
  }

  ngOnInit(): void {
  }

}
