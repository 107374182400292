import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {COINS} from "../consts/coin.consts";
import {ICoinFunction} from "../../interfaces/IGlobal.interface";
import {AuthUserService} from "./auth-user.service";

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(private router: Router, private auth: AuthUserService) {
  }

  getLastPathRouter(): string {
    return this.router.url.split('/').pop() ?? ''
  }

  getCoinFunction(value: string): ICoinFunction {
    const link = this.getLastPathRouter()
    return COINS.find(x => x.value === value)?.functions.find(y => y.link === link)! ?? ''
  }

  getShares(): { thresholdShare: number, maxShare: number } {
    const token = this.auth.user!
    const company = token.companies.find(x => x.id === token.companyIdUsed)
    return {thresholdShare: company?.thresholdShare ?? -1, maxShare: company?.maxShare ?? -1}
  }

  random_rgba() {
    const  o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ')';
  }


}
