import {Component} from '@angular/core';
import {MdbModalRef} from "mdb-angular-ui-kit/modal";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {AlertService} from "../../../services/alert.service";
import {ApiAuthService} from "../../../services/api/api-auth.service";
import {setToken} from "../../../../store/auth/auth.actions";
import {Router} from "@angular/router";
import {getUser} from "../../../../store/auth/auth.selector";

@Component({
  selector: 'app-otp-code',
  templateUrl: './otp-code.component.html',
  styleUrls: ['./otp-code.component.scss']
})
export class OtpCodeComponent {
  form: FormGroup<{ bindingCode: AbstractControl }> = new FormGroup<{ bindingCode: AbstractControl }>({
    bindingCode: new FormControl('', [Validators.required, Validators.minLength(6)])
  })
  mfaToken: string;
  oobCode: string;
  loading: boolean = false;

  constructor(
    private modalRef: MdbModalRef<OtpCodeComponent>,
    private alert: AlertService,
    private ApiAuth: ApiAuthService,
    private store: Store,
    private router: Router) {
    this.store.select(getUser).subscribe((user) => {
      if(user){
        window.location.href = '/dashboard'
      }
    })
  }

  close() {
    if (this.loading) return
    this.modalRef.close();
  }

  click() {
    if (this.form.invalid) {
     this.alert.openErrorAlert('Invalid form');
     return
    }
    this.loading = true;
    this.form.disable()
    this.ApiAuth.verifyMfa({
      mfaToken: this.mfaToken,
      oobCode: this.oobCode,
      bindingCode: this.form.get('bindingCode')?.value.toString()
    }).subscribe({
      next: (res) => {
        this.store.dispatch(setToken({ token: res.data!.accessToken }))
      },
      error: (err) => {
        this.alert.openErrorAlert(err?.message as string)
        this.loading = false;
        this.form.enable()
      }
    })
  }
}
