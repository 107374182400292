import {Injectable} from '@angular/core';
import {IToken} from '../../interfaces/IAuth';
import {Socket} from "ngx-socket-io";
import {ERole} from "../enums/EGlobal.enum";
import {select, Store} from "@ngrx/store";
import {getUser} from "../../store/auth/auth.selector";
import {filter, Observable, of, switchMap, take} from "rxjs";
import {refreshUser} from "../../store/auth/auth.actions";
import {Router} from "@angular/router";
import {AuthService} from "@auth0/auth0-angular";

@Injectable({
	providedIn: 'root'
})
export class AuthUserService {
	user$ = this.store.select(getUser)
	user: IToken;

	constructor(private socket: Socket, private store: Store, private router: Router, private authService: AuthService) {
		this.user$.subscribe(user => {
			this.user = user!;
			if (user) {
				const newUser = {...user}
				// remove companies from newUser data
				newUser.companies = []
				newUser.functions = {}
				this.socket.ioSocket.io.opts.query = {authToken: JSON.stringify(newUser!)};
				this.socket.connect()
			}
		})
		this.socket.on('logout', () => {
			this.store.dispatch(refreshUser())
		})
	}

	logout(): void {
		localStorage.removeItem('token');
		this.socket.disconnect();
		this.authService.logout({logoutParams: {returnTo: window.location.origin}});
		window.location.reload();
	}

  getAddressesByCoin(coin: string, isSendToken: boolean): { address: string, description: string }[] {
    return this.user.company.whitelistAddresses?.
    filter(x => x.coinName === coin && x.type === (isSendToken ? 'receivers' : 'validators'))
      .map(x => ({address: x.address, description: x.description})) ?? []
  }

  getWithdrawAddress(): { address: string, description: string }[] {
    return this.user.company.whitelistAddresses?.
    filter(x => x.coinName === 'ETH' && x.type === 'withdrawCredential')
      .map(x => ({address: x.address, description: x.description})) ?? []
  }

	checkRole(role: ERole[]): Observable<boolean> {
		return this.store.pipe(
			select(getUser),
			filter(user => user !== null),
			take(1),
			switchMap(user => {
				if (user) {
					const userRole = user.role
					if (!Array.isArray(userRole) || userRole.length === 0) {
						this.logout()
						return of(false)
					}

					const some = role.some(element => {
						return userRole.indexOf(element) !== -1;
					})

					if (!some) {
						if (userRole[0] === ERole.viewer) {
							this.router.navigate(['/dashboard/portfolio'])
							return of(false)
						}
						// this.router.navigate(['/dashboard/home'])
					}
					return of(some)
				} else {
					this.logout()
					return of(false)
				}
			})
		)
	}
}
