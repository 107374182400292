import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {authGuard} from "./@core/guards/auth.guard";

const routes: Routes = [
  // {path: 'login', loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule), canActivate: []},
  {path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [authGuard]},
  {path: 'complete-registration/:token', loadChildren: () => import('./components/complete-registration/complete-registration.module').then(m => m.CompleteRegistrationModule)},
  {path: 'reset-password', loadChildren: () => import('./components/reset-password/reset-password.module').then(m => m.ResetPasswordModule)},
  {path: 'change-password/:token', loadChildren: () => import('./components/change-password/change-password.module').then(m => m.ChangePasswordModule)},
  // {path: '**', redirectTo: 'dashboard'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
