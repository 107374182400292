import {Component} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {environment} from "../environments/environment";
import moment from "moment/moment";
import {AuthService} from "@auth0/auth0-angular";
import {Router} from "@angular/router";
import {ModalService} from "./@core/services/modal.service";


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	isError = false;
	loading = true;
	
	constructor(private title: Title, private authService: AuthService, private router: Router, private modalService: ModalService,) {
		title.setTitle(environment.production ? 'Colossus' : 'DEV | Colossus');
		console.log('Version:', environment.version)
		
		moment.updateLocale('en', {
			week: {
				dow: 1, // Monday is the first day of the week.
			}
		})
		this.authService.error$.subscribe((err) => {
			this.isError = true;
			console.log('error', err)
			this.modalService.openModalText('User info', err.message, 'error', 'md', 'center').subscribe(res => {
				this.authService.loginWithRedirect()
			})
		})
		
		this.authService.isAuthenticated$.subscribe((res) => {
			setTimeout(() => {
				this.loading = false;
				if (!res) {
					if (this.router.url.includes('complete-registration')) {
						return;
					}
					if (!this.isError) {
						this.authService.loginWithRedirect()
					}
				} else {
					if (!this.router.url.includes('dashboard')) {
						this.router.navigate(['/dashboard'])
					}
				}
			}, 1000)
		})
		
	}
}
