import {Component, Input, OnInit} from '@angular/core';
import {MdbModalRef, MdbModalService} from 'mdb-angular-ui-kit/modal';
import {ListRequestModalComponent} from '../modals/list-request-modal/list-request-modal.component';
import {IReceiverGetAllByChainResponse} from '../../services/api/interfaces/IReceiver';
import {ApiCosmosService} from "../../services/api/api-cosmos.service";
import {ApiReceiverService} from "../../services/api/api-receiver.service";
import {AlertService} from "../../services/alert.service";
import {ModalService} from "../../services/modal.service";


@Component({
  selector: 'app-list-receiver',
  templateUrl: './list-receiver.component.html',
  styleUrls: ['./list-receiver.component.scss']
})
export class ListReceiverComponent implements OnInit {
  @Input() listData: IReceiverGetAllByChainResponse[] = [];
  modalRef: MdbModalRef<ListRequestModalComponent> | null = null;

  constructor(private modalService: ModalService, private api: ApiReceiverService, private alertService: AlertService) {
  }

  ngOnInit() {
  }

  deleteReceiver(id: number) {
    this.modalService.openConfirmModal('Confirms', 'Are you sure you want to cancel the request?', 'md').subscribe((result) => {
      if(result){
        this.api.delete(id).catch(e => {
          this.alertService.openErrorAlert('Error deleting');
        })
      }
    })
  }
}
