<div class="modal-header alert-info">
  <h5 class="modal-title">{{title}}</h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>

<div class="modal-body word-break-word">
    <p class="m-0">
      <b>Activation epoch: </b>
      <em>{{data.activationepoch}}</em>
      <em class="ms-3">{{getTimeByEpoch(data.activationepoch)}}</em>
    </p>
  <hr class="my-2">

    <p class="m-0">
      <b>Activation eligibility epoch: </b>
      <em>{{data.activationeligibilityepoch}}</em>
      <em class="ms-3">{{getTimeByEpoch(data.activationeligibilityepoch)}}</em>
    </p>
  <hr class="my-2">

    <p class="m-0">
      <b>Exit epoch: </b>
      <em>{{data.exitepoch}}</em>
      <em class="ms-3">{{getTimeByEpoch(data.exitepoch)}}</em>
    </p>
  <hr class="my-2">

    <p class="m-0"><b>Withdrawable epoch: </b>
      <em>{{data.withdrawableepoch}}</em>
      <em class="ms-3">{{getTimeByEpoch(data.withdrawableepoch)}}</em>
    </p>
  <hr class="my-2">

    <p class="m-0"><b>BLS change epoch: </b>
      <ng-container *ngIf="loadingBls; else loadingBlsFalse">
        <div class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </ng-container>
      <ng-template #loadingBlsFalse>
        <em *ngIf="blsChange.length === 0">N.A</em>
        <span *ngIf="blsChange.length !== 0"><em>{{blsChange[0].epoch}}</em> <em class="ms-3">{{getTimeByEpoch(blsChange[0].epoch, true)}}</em></span>
      </ng-template>

    </p>
</div>


<!--{-->
<!--"activationeligibilityepoch": 53032,-->
<!--"activationepoch": 53041,-->
<!--"balance": 0,-->
<!--"effectivebalance": 0,-->
<!--"exitepoch": 72024,-->
<!--"lastattestationslot": 2304764,-->
<!--"name": "",-->
<!--"pubkey": "0xae2f2e75f66ab144fdbdabe48f292d7ba55e8bd475c22113226d4497c6adeb4bf35a7a51274ec933df3286059918992c",-->
<!--"slashed": false,-->
<!--"status": "exited",-->
<!--"validatorindex": 1712424,-->
<!--"withdrawableepoch": 72280,-->
<!--"withdrawalcredentials": "0x010000000000000000000000acc3139dd26197669012930c9daacecbe260c856",-->
<!--"total_withdrawals": 32117257594-->
<!--}-->
