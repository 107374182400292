import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalService} from "../../services/global.service";
import {Router} from "@angular/router";
import {ModalService} from "../../services/modal.service";

@Component({
  selector: 'app-list-shares',
  templateUrl: './list-shares.component.html',
  styleUrls: ['./list-shares.component.scss']
})
export class ListSharesComponent implements OnInit {

  @Input() clients: { client: number, status: boolean }[] = [];
  @Output() onLimitShares: EventEmitter<number> = new EventEmitter<number>();
  shares: {thresholdShare: number, maxShare: number} = {thresholdShare: 0, maxShare: 0};

  constructor(private globalService: GlobalService,
              private router: Router,
              private modal: ModalService) { }

  ngOnInit(): void {
    this.shares = this.globalService.getShares();
    this.onLimitShares.emit(this.shares.thresholdShare)
    if (this.shares.maxShare !== 0 && this.shares.thresholdShare === -1) {
      this.modal.openModalText('Error', 'You don\'t have any share selected', 'error').subscribe(() => {
        this.router.navigate(['/dashboard']);
      });
    }
    for(let i = 0; i < this.shares.maxShare; i++) {
      this.clients.push({client: i+1, status: false});
    }
  }

}
