<div class="table-responsive">
  <table class="table table-sm m-0">
    <thead class="table-light">
    <tr>
      <th scope="col" >Nonce</th>
      <th scope="col" >Client n°</th>
      <th scope="col">Function</th>
      <th scope="col">User</th>
      <th scope="col">Created</th>
      <th scope="col">Tools</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of listData">
      <th scope="row">{{data.sequenceNumber}}</th>
      <td>{{data.clientNumber === -1?'--':data.clientNumber}}</td>
      <td>{{data.functionName}}</td>
      <td>{{data.user?data.user.email: '--' }}</td>
      <td>
        {{data.createdAt | date: 'dd/MM/yyyy HH:mm:ss'}}
      </td>
      <td class="text-center">
        <div class=" mouse-pointer" mdbTooltip="Delete request" (click)="deleteReceiver(data.id)">
          <i class="far fa-times-circle fa-lg"></i>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
  <ng-container *ngIf="listData.length === 0">
    <p class="text-center mb-3 mt-3">There are no items to display</p>
  </ng-container>
</div>
