import { Component } from '@angular/core';
import {MdbModalRef} from "mdb-angular-ui-kit/modal";

@Component({
  selector: 'app-lens-broadcast',
  templateUrl: './lens-broadcast.component.html',
  styleUrls: ['./lens-broadcast.component.scss']
})
export class LensBroadcastComponent {
	title: string | null = null;
	data: any
	clearTx: string = 'Clear'
	hexToSign: string = ''
	constructor(private modalRef: MdbModalRef<LensBroadcastComponent>) {
	}
	
	ngOnInit(): void {
		this.hexToSign = this.data.hash
		delete this.data.hash
		this.data = JSON.stringify(this.data, null, "\t")
	}
	
	close() {
		this.modalRef.close();
	}
}
