import {Component, Input} from '@angular/core';
import {IBalanceFromAddress} from "../../services/api/interfaces/ITools";
import {Clipboard} from "@angular/cdk/clipboard";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'app-address-balance',
  templateUrl: './address-balance.component.html',
  styleUrls: ['./address-balance.component.scss']
})
export class AddressBalanceComponent {
  @Input() balance: IBalanceFromAddress
  @Input() pubKeyAddress: { publicKey: string, address: string[], explorer: string[] }
  @Input() coinName: string
  @Input() loading: boolean
  constructor(private clipboard: Clipboard, private alert: AlertService) {
  }

  copyAddress(address: string) {
    this.clipboard.copy(address)
    this.alert.openSuccessAlert('Copied address to clipboard')
  }

  balanceIsNumber(balance: number | string) {
    return typeof balance === 'number'
  }
}
