import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthUserService} from "../services/auth-user.service";
import {Subject, takeUntil} from "rxjs";

@Directive({
  selector: '[roles]'
})
export class RoleDirective implements OnInit, OnDestroy {

  @Input() roles: number[] = [];

  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private auth: AuthUserService) {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.auth.checkRole(this.roles).pipe(takeUntil(this.destroy$)).subscribe(bool => {
      bool ? this.viewContainerRef.createEmbeddedView(this.templateRef) : this.viewContainerRef.clear();
    })
  }
}
