import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timerFormat'
})
export class TimerFormatPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    const type = args[0];
    if (type === 's') {
      const seconds = Math.floor(value % 60);
      const minutes = Math.floor((value /  60) % 60);
      const hours = Math.floor(value / (60 * 60));
      return `${('0' + minutes).slice(-2)}M ${('0' + seconds).slice(-2)}S`
    } else {
      return value;
    }

  }

}
