import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlertComponent} from './components/alert/alert.component';
import {Mdb5Module} from '../modals/mdb5.module';
import {LoadingBtnDirective} from './directives/loading-btn.directive';
import {ListRequestModalComponent} from './components/modals/list-request-modal/list-request-modal.component';
import {LoadingComponent} from './components/modals/loading/loading.component';
import {TextComponent} from './components/modals/text/text.component';
import {ListReceiverComponent} from './components/list-receiver/list-receiver.component';
import {truncatePipe} from './pipes/truncate.pipe';
import {ConfirmModalComponent} from './components/modals/confirm-modal/confirm-modal.component';
import {TimerFormatPipe} from './pipes/timer-format.pipe';
import {RoleDirective} from './directives/role.directive';
import {TruncateMiddlePipe} from './pipes/truncate-middle.pipe';
import {ListSharesComponent} from './components/list-shares/list-shares.component';
import {ViewJsonComponent} from './components/modals/view-json/view-json.component';
import {OtpCodeComponent} from "./components/modals/otp-code/otp-code.component";
import {ReactiveFormsModule} from "@angular/forms";
import { AddressBalanceComponent } from './components/address-balance/address-balance.component';
import { ExistPipe } from './pipes/exist.pipe';
import { LensBroadcastComponent } from './components/modals/lens-broadcast/lens-broadcast.component';
import { InfoEthValidatorComponent } from './components/modals/info-eth-validator/info-eth-validator.component';
import { CreatePubkeyLedgerComponent } from './components/modals/create-pubkey-ledger/create-pubkey-ledger.component';

const components = [AlertComponent, ListReceiverComponent, ListSharesComponent, AddressBalanceComponent]
const modals = [ListRequestModalComponent, ConfirmModalComponent, LoadingComponent, TextComponent, OtpCodeComponent]
const directives = [LoadingBtnDirective, RoleDirective]
const pipes = [truncatePipe, TimerFormatPipe, TruncateMiddlePipe, ExistPipe]

@NgModule({
  declarations: [
    ...components,
    ...modals,
    ...directives,
    ...pipes,
    ViewJsonComponent,
    ExistPipe,
    LensBroadcastComponent,
    InfoEthValidatorComponent,
    CreatePubkeyLedgerComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    Mdb5Module,
  ],
  exports: [
    ...components,
    ...directives,
    ...pipes,
  ]
})
export class CoreModule {
}
