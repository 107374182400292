import {Component} from '@angular/core';
import {MdbModalRef} from "mdb-angular-ui-kit/modal";
import {ApiEthService} from "../../../services/api/api-eth.service";

@Component({
  selector: 'app-info-eth-validator',
  templateUrl: './info-eth-validator.component.html',
  styleUrls: ['./info-eth-validator.component.scss']
})
export class InfoEthValidatorComponent {
  title: string | null = null;
  data: any
  blsChange: any
  loadingData = true
  loadingBls = true
  epoch: number
  epochNotSet = 9223372036854776000
  constructor(private modalRef: MdbModalRef<InfoEthValidatorComponent>, private apiEth: ApiEthService) {
  }

  ngOnInit(): void {
    this.load()
  }

  async load() {
    this.epoch = Number(await this.apiEth.getLastEpoch())
    this.apiEth.getValidatorEpochInfo(this.data.pubkey).then(async res => {
      this.data = res
      const arr = ['activationepoch', 'activationeligibilityepoch', 'exitepoch', 'withdrawableepoch']
      arr.forEach(key => {
        if (this.data[key] === this.epochNotSet) {
          this.data[key] = 'Not set'
        }
      })
      this.loadingData = false
    })
    this.apiEth.getBLSChange(this.data.pubkey).then(res => {
      this.blsChange = res
        this.loadingBls = false

    })
  }

  getTimeByEpoch(epoch: number | string, isBLS = false) {
    if(epoch === 'Not set') {
      return ''
    }
    let currentLeftSeconds = 0
    const epochIsFuture = this.epoch > +epoch
    if (epochIsFuture) {
      currentLeftSeconds = (this.epoch - +epoch) * 384
    } else {
      currentLeftSeconds = (+epoch - this.epoch) * 384
    }
    return this.convertSeconds(currentLeftSeconds) + (epochIsFuture ? ' ago' : ' left')
  }

  convertSeconds(seconds: number) {
    const days = Math.floor(seconds / (24 * 3600));
    seconds %= 24 * 3600;

    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;

    const minutes = Math.floor(seconds / 60);
    seconds %= 60;

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }

  close() {
    this.modalRef.close();
  }
}
