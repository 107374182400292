<div class="modal-header alert-info">
  <h5 class="modal-title">Pubkey mancante</h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>
<div class="modal-body word-break-word">
  <p>create-pubkey-ledger works!</p>
  <button type="button" class="btn btn-block btn-primary" loadingBtn (click)="submit()" [loadingState]="loadingBtn">generate pubkey</button>
</div>
