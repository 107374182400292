import {CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {select, Store} from "@ngrx/store";
import {inject} from "@angular/core";
import {getUser} from "../../store/auth/auth.selector";
import {filter, map, of, switchMap, take} from "rxjs";
import {getUserFromToken} from "../../store/auth/auth.actions";
import {AuthService} from "@auth0/auth0-angular";

export const authGuard: CanActivateFn = (_, state) => {
	const store = inject(Store);
	const router = inject(Router);
	const authService = inject(AuthService);
	
	return authService.isAuthenticated$.pipe(
		take(1),
		switchMap(isAuthenticated => {
			if (!isAuthenticated) {
				router.navigate(['/login']);
				return of(false)
			}
			
			store.dispatch(getUserFromToken());
			
			return store.pipe(
				select(getUser),
				filter(user => user !== null),
				take(1),
				switchMap(user => {
					if (user) {
						if (state.url.includes('login')) {
							router.navigate(['/dashboard']);
						}
						return checkRoleAndRedirect(state, router, store)
					} else {
						if (state.url.includes('login')) {
							return of(true);
						} else {
							router.navigate(['/login']);
							return of(false);
						}
					}
				})
			)
			
		})
	);
	
	
	// return store.pipe(
	//   select(userIsLoggedIn),
	//   take(1),
	//   switchMap(isLoggedIn => {
	//     if (isLoggedIn) {
	//       // l'utente è gia loggato
	//       return checkRoleAndRedirect(state, router, store)
	//     }
	//
	//     // l'utente non è loggato, controlla se è presente il token
	//     if (!localStorage.getItem('token')) {
	//       if (state.url.includes('login')) {
	//         return of(true);
	//       } else {
	//         router.navigate(['/login']);
	//         return of(false);
	//       }
	//     }
	//
	//     // l'utente non è loggato, ma il token è presente, dispatch getUserFromToken
	//     store.dispatch(getUserFromToken());
	//
	//     // aspetta finche l'azione getUserDetailsSuccess o getUserDetailsFailure non è stata emessa
	//     return store.pipe(
	//       select(getUser),
	//       filter(user => user !== null),
	//       take(1),
	//       switchMap(user => {
	//         if (user) {
	//           if (state.url.includes('login')) {
	//             router.navigate(['/dashboard/home']);
	//           }
	//           return checkRoleAndRedirect(state, router, store)
	//         } else {
	//           if (state.url.includes('login')) {
	//             return of(true);
	//           } else {
	//             router.navigate(['/login']);
	//             return of(false);
	//           }
	//         }
	//       })
	//     )
	//   })
	// );
};

// function checkRoleAndRedirect(state: RouterStateSnapshot, router: Router, store: Store) {
// 	const user = store.select(getUser)
// 	return user.pipe(map(user => {
// 		if (user) {
// 			return true
// 		} else {
// 			router.navigate(['/login']);
// 			return false
// 		}
// 	}))
// }

function checkRoleAndRedirect(state: RouterStateSnapshot, router: Router, store: Store) {
	const user = store.select(getUser)
	return user.pipe(map(user => {
		if (user) {
			// const actualPath = limitedPaths.find(path => path.absolutePath === state.url);
			// if (actualPath && !actualPath!.roles.includes(user.role as ERole)) {
			// 	router.navigate(['/dashboard']);
			// 	return false;
			// }
			return true
		} else {
			router.navigate(['/login']);
			return false
		}
	}))
}
