import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exist'
})
export class ExistPipe implements PipeTransform {

  transform(value: any | string, valueDisplay: string = '--'): any {
    if (value !== undefined && value !== null && value !== '' && value !== 0) {
      return value;
    } else {
      return undefined;
    }
  }

}
