import { Component } from '@angular/core';
import {MdbModalRef} from "mdb-angular-ui-kit/modal";
import {ApiToolsService} from "../../../services/api/api-tools.service";
import {AuthUserService} from "../../../services/auth-user.service";
import {IcreateSignFirstDigestRequest} from "../../../services/api/interfaces/ITools";
import {AlertService} from "../../../services/alert.service";

@Component({
  selector: 'app-create-pubkey-ledger',
  templateUrl: './create-pubkey-ledger.component.html',
  styleUrls: ['./create-pubkey-ledger.component.scss']
})
export class CreatePubkeyLedgerComponent {
  loadingBtn = false

  constructor(private modalRef: MdbModalRef<CreatePubkeyLedgerComponent>, private apiToolsService: ApiToolsService, private auth: AuthUserService, private alert: AlertService) {
  }

  submit(){
    this.loadingBtn = true
    const user = this.auth.user
    const data: IcreateSignFirstDigestRequest = {
      account_name: user.account_name,
      testnet: user.env === 'testnet',
      companyId: user.companyIdUsed
    }
    this.apiToolsService.createSignFirstDigestRequest(data).then((res) => {
      console.log(res)
      this.alert.openSuccessAlert('Request sent successfully')
      this.loadingBtn = false
    }).catch((err) => {
      console.log(err)
      this.alert.openErrorAlert('Error sending request')
      this.loadingBtn = false
    })
  }

  close() {
    this.modalRef.close();
  }
}
