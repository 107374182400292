<div class="modal-header alert-info">
		<h5 class="modal-title">{{title}}</h5>
		<button
						type="button"
						class="btn-close"
						aria-label="Close"
						(click)="close()"
		></button>
</div>

<div class="modal-body word-break-word">
		<p class="m-0">Clear tx:</p>
		<p class="small"><i>{{clearTx}}</i></p>
		<mdb-form-control>
				<textarea mdbInput class="form-control" id="textAreaExample" cols=50 rows=13 [innerHTML]="data"></textarea>
		</mdb-form-control>
		<p class="mb-0 mt-3">Hex to sign:</p>
		<p class="small m-0"><i>{{hexToSign}}</i></p>
</div>
