import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IResponseApi} from '../../../interfaces/IApi';
import {lastValueFrom} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {IReceiverGetAllByChainResponse} from './interfaces/IReceiver';

@Injectable({
  providedIn: 'root'
})
export class ApiReceiverService {

  headers = new HttpHeaders()

  constructor(private http: HttpClient) {
  }

  getByChain(coin: string, companyId: number): Promise<IResponseApi<IReceiverGetAllByChainResponse[]>> {
    const options = {headers: this.headers, params: {coin, companyId}}
    return lastValueFrom(this.http.get<IResponseApi<IReceiverGetAllByChainResponse[]>>(environment.url_api + '/v1/receiver/getAllReceiverByChain', options))
  }

  delete(id: number): Promise<IResponseApi<null>> {
    const options = {headers: this.headers, params: {id}}
    return lastValueFrom(this.http.delete<IResponseApi<null>>(environment.url_api + '/v1/receiver/deleteById', options))
  }

}
