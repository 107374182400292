import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {IResponseApi} from "../../../interfaces/IApi";
import {ISettingsGetResponse} from "./interfaces/ISettings";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../../environments/environment";
import {IBalanceFromAddress, IcreateSignFirstDigestRequest, IGetAddressFromPubkeyRequest, IGetBalanceFromAddressRequest, IGetFireblocksPublicKeyRequest, IGetFireblocksPublicKeyResponse, IGetLedgerPublicKeyRequest, IGetLedgerPublicKeyResponse} from "./interfaces/ITools";

@Injectable({
  providedIn: 'root'
})
export class ApiToolsService {

  headers = new HttpHeaders()

  constructor(private http: HttpClient) { }

  getFireblocksPublicKeyAndAddress(data: IGetFireblocksPublicKeyRequest): Promise<IResponseApi<IGetFireblocksPublicKeyResponse>> {
    return lastValueFrom(this.http.post<IResponseApi<IGetFireblocksPublicKeyResponse>>(environment.url_api + '/v1/tools/getFireblocksPublicKey', data, {headers: this.headers}))
  }

  getLedgerPublicKeyAndAddress(data: IGetLedgerPublicKeyRequest): Promise<IResponseApi<IGetLedgerPublicKeyResponse>> {
    return lastValueFrom(this.http.post<IResponseApi<IGetLedgerPublicKeyResponse>>(environment.url_api + '/v1/tools/getLedgerPublicKey', data, {headers: this.headers}))
  }

  getAddressFromPubkey(data: IGetAddressFromPubkeyRequest): Promise<IResponseApi<IGetAddressFromPubkeyRequest>> {
    return lastValueFrom(this.http.post<IResponseApi<any>>(environment.url_api + '/v1/tools/getAddressFromPubkey', data, {headers: this.headers}))
  }

  getBalanceFromAddress(data: IGetBalanceFromAddressRequest): Promise<IResponseApi<IBalanceFromAddress>> {
    return lastValueFrom(this.http.post<IResponseApi<any>>(environment.url_api + '/v1/tools/getBalanceFromAddresses', data, {headers: this.headers}))
  }

  createSignFirstDigestRequest(data: IcreateSignFirstDigestRequest): Promise<IResponseApi<any>> {
    return lastValueFrom(this.http.post<IResponseApi<any>>(environment.url_api + '/v1/tools/createSignFirstDigestRequest', data, {headers: this.headers}))
  }


}
