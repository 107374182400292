import {Injectable} from '@angular/core';
import {throwError} from "rxjs";
import {IApiResponse} from "../../../interfaces/IApi";

@Injectable({
  providedIn: 'root'
})
export class ApiUtilsService {
  handleError = (error: any): IApiResponse<any> => {
    return throwError(() => error.error)
  }
}
