import {Injectable} from '@angular/core';
import {IResponseApi} from "../../../interfaces/IApi";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {IEthAddRequest, IEthDepositRequest, IEthEditRequest, IEthGetRequest, IEthSetStatusByIdRequest, IEthSetStatusBySequenceNumberRequest, IEthUploadChangeRequest, IEthUploadDataRequest, IEthUploadExitRequest, IEthValidator} from "./interfaces/IEth";
import {IOasisOtherfunctionsRequest} from "./interfaces/IOasis";

@Injectable({
  providedIn: 'root'
})
export class ApiEthService {

  headers = new HttpHeaders()

  constructor(private http: HttpClient) {
  }

  addRequest(data: IEthAddRequest): Promise<IResponseApi<null>> {
    return lastValueFrom(this.http.post<IResponseApi<null>>(environment.url_api + '/v1/eth/addRequest', data, {headers: this.headers}))
  }

  editRequest(data: IEthEditRequest): Promise<IResponseApi<null>> {
    return lastValueFrom(this.http.post<IResponseApi<null>>(environment.url_api + '/v1/eth/editRequest', data, {headers: this.headers}))
  }

  getAllRequests(): Promise<IEthGetRequest[]> {
    return lastValueFrom(this.http.get<IResponseApi<IEthGetRequest[]>>(environment.url_api + '/v1/eth/getAllRequests', {headers: this.headers}))
      .then(res => res.data)
  }

  deleteRequest(id: number): Promise<IResponseApi<null>> {
    return lastValueFrom(this.http.post<IResponseApi<null>>(environment.url_api + '/v1/eth/deleteRequestById', {id}, {headers: this.headers}))
  }

  uploadDataRequest(data: IEthUploadDataRequest): Promise<IResponseApi<null>> {
    return lastValueFrom(this.http.post<IResponseApi<null>>(environment.url_api + '/v1/eth/uploadJson', data, {headers: this.headers}))
  }

  uploadExitRequest(data: IEthUploadExitRequest): Promise<IResponseApi<null>> {
    return lastValueFrom(this.http.post<IResponseApi<null>>(environment.url_api + '/v1/eth/uploadExitRequest', data, {headers: this.headers}))
  }

  uploadChangeRequest(data: IEthUploadChangeRequest): Promise<IResponseApi<null>> {
    return lastValueFrom(this.http.post<IResponseApi<null>>(environment.url_api + '/v1/eth/uploadChangeRequest', data, {headers: this.headers}))
  }

  getValidatorsByRequestId(id: number): Promise<IResponseApi<IEthValidator[]>> {
    return lastValueFrom(this.http.post<IResponseApi<IEthValidator[]>>(environment.url_api + '/v1/eth/getValidatorsByRequestId', {id}, {headers: this.headers}))
  }

  getValidators(): Promise<IResponseApi<IEthValidator[]>> {
    return lastValueFrom(this.http.get<IResponseApi<IEthValidator[]>>(environment.url_api + '/v1/eth/getValidators', {headers: this.headers}))
  }

  getRequestById(id: number): Promise<IEthGetRequest> {
    return lastValueFrom(this.http.post<IResponseApi<IEthGetRequest>>(environment.url_api + '/v1/eth/getRequestById', {id}, {headers: this.headers}))
      .then(res => res.data)
  }

  deposit(data: IEthDepositRequest): Promise<IResponseApi<{ sequenceNumber: number }>> {
    return lastValueFrom(this.http.post<IResponseApi<{ sequenceNumber: number }>>(environment.url_api + '/v1/eth/deposit', data, {headers: this.headers}))
  }

  changeAddress(id: number): Promise<IResponseApi<null>> {
    return lastValueFrom(this.http.post<IResponseApi<null>>(environment.url_api + '/v1/eth/changeAddress', {id}, {headers: this.headers}))
  }

  exit(id: number): Promise<IResponseApi<null>> {
    return lastValueFrom(this.http.post<IResponseApi<null>>(environment.url_api + '/v1/eth/exit', {id}, {headers: this.headers}))
  }

  getLastEpoch(): Promise<string> {
    return lastValueFrom(this.http.get('https://holesky.beaconcha.in/api/v1/epoch/latest', {headers: this.headers}))
      .then((res:any) => res.data.epoch)
  }

  getValidatorEpochInfo(pubkey: string){
    return lastValueFrom(this.http.get(`https://holesky.beaconcha.in/api/v1/validator/${pubkey}`, {headers: this.headers})).then((res:any) => res.data)
  }

  getBLSChange(pubkey: string){
    return lastValueFrom(this.http.get(`https://holesky.beaconcha.in/api/v1/validator/${pubkey}/blsChange`, {headers: this.headers})).then((res:any) => res.data)
  }
}
