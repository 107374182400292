export enum EStatus {
  pending = 'pending',
  success = 'success',
  error = 'error',
  processing = 'processing'
}

export enum ERole {
  admin = 1000,
  user = 100,
  viewer = 90,
  monitoringColossus = 80,
}

export enum EFunction {
  SEND_TOKEN = 'sendToken'
}