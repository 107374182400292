import {Component, OnInit} from '@angular/core';
import {MdbModalRef} from 'mdb-angular-ui-kit/modal';
import {IReceiverGetAllByChainResponse} from '../../../services/api/interfaces/IReceiver';

@Component({
  selector: 'app-list-receiver-modal',
  templateUrl: './list-request-modal.component.html',
  styleUrls: ['./list-request-modal.component.scss']
})
export class ListRequestModalComponent implements OnInit {

  data!: IReceiverGetAllByChainResponse;

  constructor(public modalRef: MdbModalRef<ListRequestModalComponent>) {
  }

  ngOnInit(): void {
    console.log(this.data)
  }

}
