import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './@core/core.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
import {environment} from "../environments/environment";
import {AuthUserInterceptor} from "./@core/interceptors/auth-user.interceptor";
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {authReducer} from "./store/auth/auth.reducer";
import {AuthEffects} from "./store/auth/auth.effects";
import {AuthHttpInterceptor, AuthModule} from "@auth0/auth0-angular";

const config: SocketIoConfig = {url: environment.url_api, options: {autoConnect: false}};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    SocketIoModule.forRoot(config),
    StoreModule.forRoot({auth: authReducer}, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      }
    }),
    EffectsModule.forRoot([AuthEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !environment.production, // Restrict extension to log-only mode
    }),
	  AuthModule.forRoot({
		  domain: environment.auth0.domain,
		  clientId: environment.auth0.clientId,
		  cacheLocation: 'localstorage',
		  authorizationParams: {
			  redirect_uri: window.location.origin,
			  audience: environment.auth0.audience,
		  },
		  httpInterceptor: {
			  allowedList: [
				  {
					  // uri: `${environment.url_api}/*`,
					  uriMatcher: (uri: string): boolean => {
						  if(uri.includes(`${environment.url_api}/auth/registration`) && !uri.startsWith(`${environment.url_api}/auth/registration/send`)) return false
						  return uri.startsWith(`${environment.url_api}/`)
					  },
					  tokenOptions: {
						  authorizationParams: {
							  audience: environment.auth0.audience,
						  }
					  }
				  }
			  
			  ]
		  }
	  }),
	  
  ],
  providers: [
	  { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
