import {createAction, props} from "@ngrx/store";
import {IToken, IUser} from "../../interfaces/IAuth";

export const setToken = createAction('[Auth] Set Token', props<{ token: string }>());
export const setUser = createAction('[Auth] Set User', props<{ user: IToken }>());
export const getUserFromToken = createAction('[Auth] Get User from Token');
export const userFailure = createAction('[Auth] User Failure', props<{ error: any }>());
export const logout = createAction('[Auth] Logout');
export const logoutSuccess = createAction('[Auth] Logout Success');
export const refreshUser = createAction('[Auth] Refresh User');
export const refreshUserSuccess = createAction('[Auth] Refresh User Success', props<{ user: any }>());
export const refreshUserFailure = createAction('[Auth] Refresh User Failure', props<{ error: any }>());
