import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {catchError, lastValueFrom, map, Observable} from 'rxjs';
import {IApiResponse, IResponseApi} from '../../../interfaces/IApi';
import {IChangeCompanyUser, ICheckRequestLogout, ILoginReq, ILoginRes, IToken, IUser, IUserRegistration, IUserUpdate, IVerifyMfaReq, IVerifyMfaRes} from '../../../interfaces/IAuth';
import {UtilsService} from "../utils.service";
import {ApiUtilsService} from "./api-utils.service";
import _default from "chart.js/dist/core/core.interaction";
import dataset = _default.modes.dataset;

@Injectable({
  providedIn: 'root'
})
export class ApiAuthService {
  headers = new HttpHeaders()

  constructor(private http: HttpClient, private apiUtils: ApiUtilsService) {
  }

  login(data: ILoginReq): IApiResponse<ILoginRes> {
    return this.http.post<IResponseApi<ILoginRes>>(environment.url_api + '/auth/login', data, {headers: this.headers}).pipe(catchError(this.apiUtils.handleError))
  }

  verifyMfa(data: IVerifyMfaReq): IApiResponse<IVerifyMfaRes> {
    return this.http.post<IResponseApi<IVerifyMfaRes>>(environment.url_api + '/auth/login/verifyMfa', data, {headers: this.headers}).pipe(catchError(this.apiUtils.handleError))
  }

  registration(user: IUserRegistration): Promise<IResponseApi<any>> {
    return lastValueFrom(this.http.post<IResponseApi<any>>(environment.url_api + '/auth/registration/send', user, {headers: this.headers}))
  }

  completeRegistration(data: {password: string, token: string}): Promise<IResponseApi<any>> {
    return lastValueFrom(this.http.post<IResponseApi<any>>(environment.url_api + `/auth/registration/${data.token}`, {password:data.password}, {headers: this.headers}))
  }

  update(user: IUserUpdate): Promise<IResponseApi<any>> {
    return lastValueFrom(this.http.post<IResponseApi<any>>(environment.url_api + '/auth/updateByUUId', user, {headers: this.headers}))
  }

  getAll$(): Observable<IUser[]> {
    return this.http.get<IResponseApi<IUser[]>>(environment.url_api + '/auth/getAllUsers', {headers: this.headers})
      .pipe(map(r => r.data))
  }

  getByUUId(uuid: string): Promise<IUser> {
    return lastValueFrom(this.http.post<IResponseApi<IUser>>(environment.url_api + '/auth/getByUUId', {uuid}, {headers: this.headers})).then(r => r.data)
  }

  checkRequestLogout(uuid: string): Promise<ICheckRequestLogout> {
    return lastValueFrom(this.http.post<IResponseApi<ICheckRequestLogout>>(environment.url_api + '/auth/checkRequestLogout', {uuid}, {headers: this.headers})).then(r => r.data)
  }

  changeCompanyUser(uuid: string, companyIdUsed: number): Promise<IChangeCompanyUser> {
    return lastValueFrom(this.http.post<IResponseApi<IChangeCompanyUser>>(environment.url_api + '/auth/changeCompanyUser', {uuid, companyIdUsed}, {headers: this.headers})).then(r => r.data)
  }

  refreshToken(uuid: string): Promise<any> {
    return lastValueFrom(this.http.post<IResponseApi<any>>(environment.url_api + '/auth/refreshToken', {uuid}, {headers: this.headers})).then(r => r.data)
  }

  getMe(): Observable<IToken | any> {
    return this.http.get<IResponseApi<IToken | any>>(environment.url_api + '/auth/getMe', {headers: this.headers}).pipe(
      map(r => {
        if(r.isOkPubKey && !r.isOkPubKey.status) {
          console.error(r.isOkPubKey.message)
        }
        return r.data
      }),
    )
  }

  resetPasswordSendEmail(email: string): Promise<IResponseApi<any>> {
    return lastValueFrom(this.http.patch<IResponseApi<any>>(environment.url_api + '/auth/resetPassword/send', {email}, {headers: this.headers}))
  }

  resetPassword(token: string, password: string): Promise<IResponseApi<any>> {
    return lastValueFrom(this.http.patch<IResponseApi<any>>(environment.url_api + `/auth/resetPassword/${token}`, {password}, {headers: this.headers}))
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

}
