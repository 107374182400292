import {Component, OnInit} from '@angular/core';
import {MdbModalRef} from "mdb-angular-ui-kit/modal";

@Component({
    selector: 'app-view-json',
    templateUrl: './view-json.component.html',
    styleUrls: ['./view-json.component.scss']
})
export class ViewJsonComponent implements OnInit {
    title: string | null = null;
    json: Object
    text: string

    constructor(private modalRef: MdbModalRef<ViewJsonComponent>) {
    }

    ngOnInit(): void {
        this.text = JSON.stringify(this.json, null, "\t")
    }

    close() {
        this.modalRef.close();
    }

}
