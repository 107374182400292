import packageInfo from '../../package.json';

export const environment = {
	version: packageInfo.version + '-dev',
	production: false,
	url_api: 'https://dev-rpc.colossus.life:5001',
  auth0: {
		domain: "dev.auth.colossus.life",
		clientId: "aKjgkDC7cOCFFiuyVDZNbqSUr41L7nkz",
		audience: "https://development-colossusdigital.eu.auth0.com/api/v2/",
	}
};
