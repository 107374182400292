import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MdbAccordionModule} from 'mdb-angular-ui-kit/accordion';
import {MdbRatingModule} from 'mdb-angular-ui-kit/rating';
import {MdbFormsModule} from 'mdb-angular-ui-kit/forms';
import {MdbCheckboxModule} from 'mdb-angular-ui-kit/checkbox';
import {MdbValidationModule} from 'mdb-angular-ui-kit/validation';
import {MdbNotificationModule} from 'mdb-angular-ui-kit/notification';
import {MdbModalModule} from 'mdb-angular-ui-kit/modal';
import {MdbSidenavModule} from 'mdb-angular-ui-kit/sidenav';
import {MdbTooltipModule} from 'mdb-angular-ui-kit/tooltip';
import {MdbCollapseModule} from 'mdb-angular-ui-kit/collapse';
import {MdbSelectModule} from 'mdb-angular-ui-kit/select';
import {MdbChartModule} from 'mdb-angular-ui-kit/charts';
import {MdbTabsModule} from 'mdb-angular-ui-kit/tabs';
import {MdbTableModule} from 'mdb-angular-ui-kit/table';
import {MdbScrollbarModule} from 'mdb-angular-ui-kit/scrollbar';
import {MdbDatepickerModule} from 'mdb-angular-ui-kit/datepicker';
import {MdbRippleModule} from 'mdb-angular-ui-kit/ripple';
import {MdbDropdownModule} from "mdb-angular-ui-kit/dropdown";
import {FormsModule} from "@angular/forms";
import {A11yModule} from "@angular/cdk/a11y";
import {MdbInputMaskModule} from "mdb-angular-input-mask";
import {MdbOptionModule} from "mdb-angular-ui-kit/option";
import {MdbRadioModule} from "mdb-angular-ui-kit/radio";

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [
    MdbAccordionModule,
    MdbRatingModule,
    MdbFormsModule,
    MdbValidationModule,
    MdbCheckboxModule,
    MdbNotificationModule,
    MdbModalModule,
    MdbSidenavModule,
    MdbTooltipModule,
    MdbCollapseModule,
    MdbSelectModule,
    MdbChartModule,
    MdbTabsModule,
    MdbTableModule,
    MdbScrollbarModule,
    MdbDatepickerModule,
    MdbAccordionModule,
    MdbRippleModule,
    MdbDropdownModule,
    MdbFormsModule,
    MdbTableModule,
    MdbFormsModule,
    FormsModule,
    A11yModule,
    MdbCheckboxModule,
    MdbInputMaskModule,
    MdbValidationModule,
    MdbScrollbarModule,
    MdbTableModule,
    MdbFormsModule,
    MdbOptionModule,
    MdbSelectModule,
    MdbScrollbarModule,
    MdbTableModule,
    MdbTooltipModule,
    MdbRadioModule
  ]
})
export class Mdb5Module {
}
