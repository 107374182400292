import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateMiddle'
})
export class TruncateMiddlePipe implements PipeTransform {

  transform(value: string, args: any[]): string {
    if(value.length < args[0] + args[1]){
      return value;
    }
    const first = value.substring(0, args[0]);
    const last = value.substring(value.length - args[1], value.length);
    return first + '...' + last;
  }

}
