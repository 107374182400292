<ng-container *ngIf="clients.length !== 0; else noShare">
  <ul class="list-group list-group-horizontal">
    <li *ngFor="let row of clients;" class="list-group-item">
      Client {{row.client}}
      <i [class.client-status-active]="row.status" class="fas fa-circle client-status"></i>
    </li>
  </ul>

</ng-container>
<ng-template #noShare>
  Shares not needed
</ng-template>
