import {ICoin} from "../../interfaces/IGlobal.interface";
import { EFunction } from "../enums/EGlobal.enum";

export const COINS: ICoin[] = [
  {
    label: 'Atom',
    value: 'atom',
    coinTicker: 'ATOM',
    status: true,
    validator: true,
    provider: 'fireblocks',
    img: 'assets/loghi_coin/atom.svg',
    functions: [
      {label: 'Send token', value: EFunction.SEND_TOKEN, active: true, link: 'send-token'},
      {label: 'Delegate token', value: 'delegateToken', active: true, link: 'delegate-token'},
      {label: 'Undelegate token', value: 'undelegateToken', active: true, link: 'undelegate-token'},
      {label: 'Withdraw rewards', value: 'withdrawRewards', active: true, link: 'withdraw-tokens-rewards'},
      {label: 'Redelegate token', value: 'redelegateToken', active: false, link: '#'},
      {label: 'Withdraw commissions', value: 'withdrawCommissions', active: false, link: '#'},
      {label: 'REStake On', value: 'restakeOn', active: false, link: '#', group: 'restake'},
      {label: 'REStake Off', value: 'restakeOff', active: false, link: '#', group: 'restake'},
    ]
  },
  {
    label: 'Kava',
    value: 'kava',
    coinTicker: 'KAVA',
    status: true,
    validator: true,
    img: 'assets/loghi_coin/kava.svg',
    functions: [
      {label: 'Send token', value: EFunction.SEND_TOKEN, active: true, link: 'send-token'},
      {label: 'Delegate token', value: 'delegateToken', active: true, link: 'delegate-token'},
      {label: 'Undelegate token', value: 'undelegateToken', active: true, link: 'undelegate-token'},
      {label: 'Withdraw rewards', value: 'withdrawRewards', active: true, link: 'withdraw-tokens-rewards'},
      {label: 'Redelegate token', value: 'redelegateToken', active: false, link: '#'},
      {label: 'Withdraw commissions', value: 'withdrawCommissions', active: false, link: '#'},
      {label: 'REStake On', value: 'restakeOn', active: false, link: '#', group: 'restake'},
      {label: 'REStake Off', value: 'restakeOff', active: false, link: '#', group: 'restake'},
    ]
  },
  {
    label: 'Secret',
    value: 'secret',
    coinTicker: 'SCRT',
    status: true,
    validator: true,
    img: 'assets/loghi_coin/secret.svg',
    functions: [
      {label: 'Send token', value: EFunction.SEND_TOKEN, active: true, link: 'send-token'},
      {label: 'Delegate token', value: 'delegateToken', active: true, link: 'delegate-token'},
      {label: 'Undelegate token', value: 'undelegateToken', active: true, link: 'undelegate-token'},
      {label: 'Withdraw rewards', value: 'withdrawRewards', active: true, link: 'withdraw-tokens-rewards'},
      {label: 'Redelegate token', value: 'redelegateToken', active: false, link: '#'},
      {label: 'Withdraw commissions', value: 'withdrawCommissions', active: false, link: '#'},
      {label: 'REStake On', value: 'restakeOn', active: false, link: '#', group: 'restake'},
      {label: 'REStake Off', value: 'restakeOff', active: false, link: '#', group: 'restake'},
    ]
  },
  {
    label: 'Akash',
    value: 'akt',
    coinTicker: 'AKT',
    status: true,
    validator: true,
    img: 'assets/loghi_coin/akt.svg',
    functions: [
      {label: 'Send token', value: EFunction.SEND_TOKEN, active: true, link: 'send-token'},
      {label: 'Delegate token', value: 'delegateToken', active: true, link: 'delegate-token'},
      {label: 'Undelegate token', value: 'undelegateToken', active: true, link: 'undelegate-token'},
      {label: 'Withdraw rewards', value: 'withdrawRewards', active: true, link: 'withdraw-tokens-rewards'},
      {label: 'Redelegate token', value: 'redelegateToken', active: false, link: '#'},
      {label: 'Withdraw commissions', value: 'withdrawCommissions', active: false, link: '#'},
      {label: 'REStake On', value: 'restakeOn', active: false, link: '#', group: 'restake'},
      {label: 'REStake Off', value: 'restakeOff', active: false, link: '#', group: 'restake'},
    ]
  },
  {
    label: 'Cartesi',
    value: 'ctsi',
    coinTicker: 'CTSI',
    status: true,
    provider: 'fireblocks',
    validator: true,
    img: 'assets/loghi_coin/ctsi.svg',
    functions: [
      {label: 'Send token', value: EFunction.SEND_TOKEN, active: true, link: 'send-token'},
      {label: 'Approve validator', value: 'approveValidator', active: true, link: 'approve-validator'},
      {label: 'Deposit for staking', value: 'depositForStaking', active: true, link: 'deposit-for-staking'},
      {label: 'Stake tokens', value: 'stakeTokens', active: true, link: 'stake-tokens'},
      {label: 'Unstake tokens', value: 'unstakeTokens', active: true, link: 'unstake-tokens-tokens'},
      {label: 'Unstake all tokens', value: 'unstakeAllTokens', active: true, link: 'unstake-tokens-all-tokens'},
      {label: 'Withdraw tokens', value: 'withdrawTokens', active: true, link: 'withdraw-tokens-tokens'},
    ]
  },
  {
    label: 'Oasis',
    value: 'oasis',
    coinTicker: 'ROSE',
    status: true,
    validator: true,
    img: 'assets/loghi_coin/oasis.svg',
    functions: [
      {label: 'Send token', value: EFunction.SEND_TOKEN, active: true, link: 'send-token'},
      {label: 'Add escrow', value: 'addEscrow', active: true, link: 'add-escrow'},
      {label: 'Reclaim escrow', value: 'reclaimEscrow', active: true, link: 'reclaim-escrow'},
      {label: 'Reclaim all escrow', value: 'reclaimAllEscrow', active: true, link: 'reclaim-all-escrow'},
    ]
  },
  {
    label: 'Flow',
    value: 'flow',
    coinTicker: 'FLOW',
    status: true,
    validator: false,
    img: 'assets/loghi_coin/flow.svg',
    functions: [
      {label: 'Send token', value: EFunction.SEND_TOKEN, active: true, link: 'send-token'},
      {label: 'Register delegator', value: 'registerDelegator', active: true, link: 'register-delegator'},
      {label: 'Stake tokens', value: 'stakeTokens', active: true, link: 'stake-tokens'},
      {label: 'Unstake tokens', value: 'unstakeTokens', active: true, link: 'unstake-tokens-tokens'},
      {label: 'Withdraw unstaked tokens', value: 'withdrawUnstakedTokens', active: true, link: 'withdraw-tokens-unstaked-tokens'},
      {label: 'Stake rewards', value: 'stakeRewards', active: true, link: 'stake-rewards'},
      {label: 'Withdraw rewards', value: 'withdrawRewards', active: true, link: 'withdraw-rewards'},
    ]
  },
  {
    label: 'Ethereum',
    value: 'eth',
    coinTicker: 'ETH',
    status: true,
    provider: 'fireblocks',
    validator: true,
    img: ' assets/loghi_coin/eth.svg',
    functions: [
      {label: 'Deposit', value: 'deposit', active: true, link: 'deposit'},
    ]
  },
  {
    label: 'Tezos',
    value: 'tezos',
    coinTicker: 'XTZ',
    status: true,
    provider: 'fireblocks',
    validator: true,
    img: 'assets/loghi_coin/tezos.svg',
    functions: [
      {label: 'Send token', value: EFunction.SEND_TOKEN, active: true, link: 'send-token'},
      {label: 'Delegate wallet', value: 'delegateWallet', active: true, link: 'delegate-wallet'},
    ]
  },
  {
    label: 'Ada',
    value: 'ada',
    coinTicker: 'ADA',
    status: false,
    validator: true,
    img: 'assets/loghi_coin/ada.svg',
    functions: []
  },
  {
    label: 'Polygon',
    value: 'matic',
    coinTicker: 'MATIC',
    status: true,
    provider: 'fireblocks',
    validator: false,
    img: 'assets/loghi_coin/matic.svg',
    functions: [
      {label: 'Approve delegator', value: 'approveDelegator', active: true, link: 'approve-delegator'},
      {label: 'Buy voucher', value: 'buyVoucher', active: true, link: 'buy-voucher'},
      {label: 'Restake rewards', value: 'restakeRewards', active: true, link: 'restake-rewards'},
      {label: 'Sell voucher', value: 'sellVoucher', active: true, link: 'sell-voucher'}
    ]
  },
  {
    label: 'Fantom',
    value: 'fantom',
    coinTicker: 'FTM',
    status: false,
    validator: true,
    img: 'assets/loghi_coin/fantom.svg',
    functions: []
  },
  {
    label: 'Near',
    value: 'near',
    coinTicker: 'NEAR',
    status: true,
    provider: 'fireblocks',
    validator: true,
    img: 'assets/loghi_coin/near.svg',
    functions: [
      {label: 'Send token', value: EFunction.SEND_TOKEN, active: true, link: 'send-token'},
      {label: 'Deposit and stake', value: 'depositAndStake', active: true, link: 'deposit-and-stake'},
      {label: 'Unstake tokens', value: 'unstakeTokens', active: true, link: 'unstake-tokens'},
      {label: 'Withdraw tokens', value: 'withdrawTokens', active: true, link: 'withdraw-tokens'},
    ]
  },
  {
    label: 'Harmony',
    value: 'harmony',
    coinTicker: 'ONE',
    status: true,
    validator: true,
    lens: true,
    img: 'assets/loghi_coin/harmony.svg',
    functions: [
      {label: 'Send token', value: EFunction.SEND_TOKEN, active: true, link: 'send-token'},
      {label: 'Delegate tokens', value: 'delegateTokens', active: true, link: 'delegate-tokens'},
      {label: 'Undelegate tokens', value: 'undelegateTokens', active: true, link: 'undelegate-tokens'},
      {label: 'Collect rewards', value: 'collectRewards', active: true, link: 'collect-rewards'}
    ]
  },
  {
    label: 'Avalanche',
    value: 'avax',
    coinTicker: 'AVAX',
    status: true,
    provider: 'fireblocks',
    validator: true,
    img: 'assets/loghi_coin/avax.svg',
    functions: [
      {label: 'Export from C', value: 'sendTokensCToP', active: true, link: 'send-tokens-c-to-p'},
      {label: 'Import to P', value: 'sendTokensCToPImport', active: true, link: 'send-tokens-c-to-p-import'},
      {label: 'Export from P', value: 'sendTokensPToCExport', active: true, link: 'send-tokens-p-to-c-export'},
      {label: 'Import to C', value: 'sendTokensPToC', active: true, link: 'send-tokens-p-to-c'},
      {label: 'Delegate tokens', value: 'delegateTokens', active: true, link: 'delegate-tokens'},
      {label: 'Add validator', value: 'addValidator', active: true, link: 'add-validator'},
    ]
  },
  {
    label: 'Polkadot',
    value: 'dot',
    coinTicker: 'DOT',
    status: true,
    provider: 'fireblocks',
    validator: true,
    img: 'assets/loghi_coin/dot.svg',
    functions: [
      {label: 'Send tokens', value: EFunction.SEND_TOKEN, active: true, link: 'send-tokens'},
      {label: 'Delegate tokens', value: 'delegateTokens', active: true, link: 'delegate-tokens'},
      {label: 'Unbond tokens', value: 'unbondTokens', active: true, link: 'unbond-tokens'},
      {label: 'Withdraw unbonded tokens', value: 'withdrawUnbondedTokens', active: true, link: 'withdraw-unbonded-tokens'},
      {label: 'Chill', value: 'chill', active: true, link: 'chill'},
      {label: 'Add to delegation', value: 'addToDelegation', active: true, link: 'add-to-delegation'},
      {label: 'Change validators', value: 'changeValidators', active: true, link: 'change-validators'},
    ]
  },
  {
    label: 'Kusama',
    value: 'kusama',
    coinTicker: 'KSM',
    status: true,
    provider: 'fireblocks',
    validator: true,
    img: 'assets/loghi_coin/kusama.svg',
    functions: [
      {label: 'Send tokens', value: EFunction.SEND_TOKEN, active: true, link: 'send-tokens'},
      {label: 'Delegate tokens', value: 'delegateTokens', active: true, link: 'delegate-tokens'},
      {label: 'Unbond tokens', value: 'unbondTokens', active: true, link: 'unbond-tokens'},
      {label: 'Withdraw unbonded tokens', value: 'withdrawUnbondedTokens', active: true, link: 'withdraw-unbonded-tokens'},
      {label: 'Chill', value: 'chill', active: true, link: 'chill'},
      {label: 'Add to delegation', value: 'addToDelegation', active: true, link: 'add-to-delegation'},
      {label: 'Change validators', value: 'changeValidators', active: true, link: 'change-validators'},
    ]
  },
  {
    label: 'Celestia',
    value: 'tia',
    coinTicker: 'TIA',
    status: true,
    wip: false,
    provider: 'fireblocks',
    validator: true,
    img: 'assets/loghi_coin/tia.svg',
    functions: [
      {label: 'Send token', value: EFunction.SEND_TOKEN, active: true, link: 'send-token'},
      {label: 'Delegate token', value: 'delegateToken', active: true, link: 'delegate-token'},
      {label: 'Undelegate token', value: 'undelegateToken', active: true, link: 'undelegate-token'},
      {label: 'Withdraw rewards', value: 'withdrawRewards', active: true, link: 'withdraw-tokens-rewards'},
      {label: 'Redelegate token', value: 'redelegateToken', active: false, link: '#'},
      {label: 'Withdraw commissions', value: 'withdrawCommissions', active: false, link: '#'},
    ]
  },
  {
    label: 'Dydx',
    value: 'dydx',
    coinTicker: 'DYDX',
    status: true,
    wip: false,
    provider: 'fireblocks',
    validator: true,
    img: 'assets/loghi_coin/dydx.svg',
    functions: [
      {label: 'Send token', value: EFunction.SEND_TOKEN, active: true, link: 'send-token'},
      {label: 'Delegate token', value: 'delegateToken', active: true, link: 'delegate-token'},
      {label: 'Undelegate token', value: 'undelegateToken', active: true, link: 'undelegate-token'},
      {label: 'Withdraw rewards', value: 'withdrawRewards', active: true, link: 'withdraw-tokens-rewards'},
      {label: 'Redelegate token', value: 'redelegateToken', active: false, link: '#'},
      {label: 'Withdraw commissions', value: 'withdrawCommissions', active: false, link: '#'},
    ]
  },
  {
    label: 'Solana',
    value: 'sol',
    coinTicker: 'SOL',
    status: true,
    wip: true,
    provider: 'fireblocks',
    validator: true,
    img: 'assets/loghi_coin/solana.svg',
    functions: []
  },
  {
    label: 'The Graph',
    value: 'grt',
    coinTicker: 'GRT',
    status: true,
    wip: true,
    provider: 'fireblocks',
    validator: false,
    img: 'assets/loghi_coin/theGraph.svg',
    functions: []
  },
  {
    label: 'Evmos',
    value: 'evmos',
    coinTicker: 'EVMOS',
    status: true,
    wip: true,
    provider: 'fireblocks',
    validator: false,
    img: 'assets/loghi_coin/evmos.svg',
    functions: []
  },
  {
    label: 'Islamic Coin',
    value: 'islm',
    coinTicker: 'HAQQ',
    status: true,
    wip: false,
    validator: true,
    img: 'assets/loghi_coin/islm.svg',
    functions: [
      {label: 'Send token', value: EFunction.SEND_TOKEN, active: true, link: 'send-token'},
      {label: 'Delegate token', value: 'delegateToken', active: true, link: 'delegate-token'},
      {label: 'Undelegate token', value: 'undelegateToken', active: true, link: 'undelegate-token'},
      {label: 'Withdraw rewards', value: 'withdrawRewards', active: true, link: 'withdraw-tokens-rewards'}
    ]
  },
  {
    label: 'Q',
    value: 'q',
    coinTicker: 'Q',
    status: true,
    validator: true,
    provider: 'fireblocks',
    img: 'assets/loghi_coin/q.svg',
    functions: [
      {label: 'Send token', value: EFunction.SEND_TOKEN, active: true, link: 'send-tokens', group: 'Governance'},
      {label: 'Deposit to Q vault', value: 'depositToQVault', active: true, link: 'depositToQVault', group: 'Vault'},
      {label: 'Lock tokens for voting', value: 'lockTokensForVoting', active: true, link: 'lock-tokens-for-voting', group: 'Vault'},
      {label: 'Unlock tokens', value: 'unlockTokens', active: true, link: 'unlock-tokens', group: 'Vault'},
      {label: 'Withdraw tokens from Q vault', value: 'withdrawTokensFromQVault', active: true, link: 'withdraw-tokens-from-q-vault', group: 'Vault'},
      {label: 'Delegate your voting power part 1.', subLabel: 'Announce new voting agent', value: 'delegateYourVotingPowerPart1', active: true, link: 'announce-new-voting-agent', group: 'Vault'},
      {label: 'Delegate your voting power part 2.', subLabel: 'Set new voting agent', value: 'delegateYourVotingPowerPart2', active: true, link: 'set-new-voting-agent', group: 'Vault'},
      {label: 'Remove voting agent', value: 'removeVotingAgent', active: true, link: 'remove-voting-agent', group: 'Vault'},
      {label: 'Delegate to a validator', value: 'delegateToAValidator', active: true, link: 'delegate-to-a-validator', group: 'Stake Delegation'},
      {label: 'Claim validator rewards', value: 'claimValidatorRewards', active: true, link: 'claim-validator-rewards', group: 'Stake Delegation'},
      {label: 'Remove delegation', value: 'removeDelegation', active: true, link: 'remove-delegation', group: 'Stake Delegation'},

      // {label: 'Deposit to staking contract', value: 'depositToStakingContract', active: true, link: 'deposit-to-staking-contract'},
      // {label: 'Lock Tokens', value: 'lockTokens', active: true, link: 'lock-tokens'},
      // {label: 'Unlock Tokens', value: 'unlockTokens', active: true, link: 'unlock-tokens'},
      // {label: 'Withdraw Tokens', value: 'withdrawTokens', active: true, link: 'withdraw-tokens'},
      // {label: 'Announce new voting agent', value: 'announceNewVotingAgent', active: true, link: 'announce-new-voting-agent'},
      // {label: 'Set new voting agent', value: 'setNewVotingAgent', active: true, link: 'set-new-voting-agent'},
    ]
  },
  {
    label: 'Iotx',
    value: 'iotx',
    coinTicker: 'IOTX',
    status: true,
    wip: false,
    validator: false,
    img: ' assets/loghi_coin/iotx.svg',
    functions: [
      {label: 'Send token', value: EFunction.SEND_TOKEN, active: true, link: 'send-tokens'},
      {label: 'Create stake', value: 'createStake', active: true, link: 'create-stake'}
    ]
  },
  {
    label: 'Stacks',
    value: 'stacks',
    coinTicker: 'STX',
    status: true,
    wip: true,
    validator: false,
    img: ' assets/loghi_coin/stacks.svg',
    functions: [
    ]
  },
  {
    label: 'Polymesh',
    value: 'polymesh',
    coinTicker: 'POLYX',
    status: true,
    wip: true,
    validator: false,
    img: ' assets/loghi_coin/polymesh.svg',
    functions: [
    ]
  },
  {
    label: 'Usdc',
    value: 'usdc',
    coinTicker: 'USDC',
    status: true,
    validator: false,
    img: 'assets/loghi_coin/usdc_logo.svg',
    functions: []
  },
  {
    label: 'SSV',
    value: 'ssv',
    coinTicker: 'SSV',
    status: true,
    wip: false,
    validator: true,
    img: ' assets/loghi_coin/ssv.svg',
    functions: [
      {label: 'Approve SSV Tokens', value: 'approve', active: true, link: 'approve', group: 'Cluster Management'},
      {label: 'Deposit SSV Tokens', value: 'deposit', active: true, link: 'deposit', group: 'Cluster Management'},
      {label: 'Register validator', value: 'registerValidator', active: true, link: 'register-validator', group: 'Cluster Management'},
      {label: 'Remove validator', value: 'removeValidator', active: true, link: 'remove-validator', group: 'Cluster Management'},
      {label: 'Register operator', value: 'registerOperator', active: true, link: 'register-operator', group: 'Operator Management'},
      {label: 'Withdraw tokens', value: 'withdrawTokens', active: true, link: 'withdraw-tokens', group: 'Cluster Management'},
      {label: 'Set fee recipient address', value: 'setFeeRecipientAddress', active: true, link: 'set-fee-recipient-address', group: 'Cluster Management'},
      {label: 'Set operator whitelist', value: 'setOperatorWhitelist', active: true, link: 'set-operator-whitelist', group: 'Operator Management'},
    ]
  }
]

export enum ECoin {
  atom = 'atom',
  kava = 'kava',
  secret = 'secret',
  tezos = 'tezos',
  cartesi = 'ctsi',
  oasis = 'oasis',
  flow = 'flow',
  near = 'near',
  harmony = 'harmony',
  avax = 'avax',
  eth = 'eth',
  dot = 'dot',
  kusama = 'kusama',
  ssv = 'ssv',
}
