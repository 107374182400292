<div class="card">
  <div class="card-header">
    <h5 class="m-0">Wallet details</h5>
  </div>
  <div class="card-body py-2 px-4">
    <ng-container *ngIf="!loading; else isLoading">

      <div class="d-flex align-items-center" *ngFor="let row of pubKeyAddress.address; let i = index">
        <i class="fas fa-wallet pe-2"></i>
        <em style="font-size: 14px;">{{ row }}</em>
        <ng-container *ngIf="row !== 'missing'">
          <i class="far fa-clone ms-2 mouse-pointer" (click)="copyAddress(pubKeyAddress.address[i])" [mdbTooltip]="'Copy address'"></i>
          <a class="mouse-pointer ms-3" [mdbTooltip]="'Explorer'" [href]="pubKeyAddress.explorer[i]" target="_explorer">
            <i class="fas fa-external-link-alt fa-xs"></i>
          </a>
        </ng-container>
      </div>

      <hr class="my-1">
      <div class="d-flex align-items-center">
        <i class="fas fa-money-bill-wave pe-2"></i>
        <ng-container *ngIf="balance !== undefined">

          <ng-container [ngSwitch]="coinName">
            <ng-container *ngSwitchCase="'ssv'">
              <em class="me-2" style="font-size: 14px;"><b>ETH Balance:</b> {{ balanceIsNumber(balance.eth_balance!) ? (balance.eth_balance | number : '0.2-20') : balance.eth_balance }}</em>
              <em class="me-2" style="font-size: 14px;"><b>SSV Balance:</b> {{ balanceIsNumber(balance.ssv_balance!) ? (balance.ssv_balance | number : '0.2-2') : balance.ssv_balance }}</em>
            </ng-container>
            <ng-container *ngSwitchCase="'q'">
              <em class="me-2" style="font-size: 14px;"><b>Available:</b> {{ balanceIsNumber(balance.available!) ? (balance.available | number : '0.2-2') : balance.available }}</em>
              <em class="me-2" style="font-size: 14px;"><b>Delegated:</b> {{ balanceIsNumber(balance.delegated!) ? (balance.delegated | number : '0.2-2') : balance.delegated }}</em>
              <em class="me-2" style="font-size: 14px;"><b>Deposited:</b> {{ balanceIsNumber(balance.deposited!) ? (balance.deposited | number : '0.2-2') : balance.deposited }}</em>
              <em class="me-2" style="font-size: 14px;"><b>Locked:</b> {{ balanceIsNumber(balance.locked!) ? (balance.locked | number : '0.2-2') : balance.locked }}</em>
              <em class="me-2" style="font-size: 14px;"><b>Total balance:</b> {{ balanceIsNumber(balance.total_balance!) ? (balance.total_balance | number : '0.2-2') : balance.total_balance }}</em>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <em class="me-2" style="font-size: 14px;"><b>Available:</b> {{ balanceIsNumber(balance.available!) ? (balance.available | number : '0.2-2') : balance.available }}</em>
              <em class="me-2" style="font-size: 14px;"><b>Delegated:</b> {{ balanceIsNumber(balance.delegated!) ? (balance.delegated | number : '0.2-2') : balance.delegated }}</em>
              <em class="me-2" style="font-size: 14px;"><b>Rewards:</b> {{ balanceIsNumber(balance.rewards_to_claim!) ? (balance.rewards_to_claim | number : '0.2-2') : balance.rewards_to_claim }}</em>
              <em class="" style="font-size: 14px;"><b>Unbonding:</b> {{ balanceIsNumber(balance.unbonding!) ? (balance.unbonding | number : '0.2-2') : balance.unbonding }}</em>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #isLoading>
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </ng-template>
  </div>
</div>
