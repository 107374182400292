import * as AuthActions from './auth.actions';
import {createReducer, on} from "@ngrx/store";
import {IToken, IUser} from "../../interfaces/IAuth";
import {IResponseApi} from "../../interfaces/IApi";

export interface AuthState {
  user: IToken | null | undefined
  error: IResponseApi<any> | null;
}

export const initialState: AuthState = {
  user: null,
  error: null
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.setUser, (state, { user }) => ({ ...state, user, error: null })),
  on(AuthActions.userFailure, (state, { error }) => ({ ...state, error, user: null })),
  on(AuthActions.logoutSuccess, state => ({ ...state, user: null, error: null })),
  on(AuthActions.refreshUserSuccess, (state, {user}) => ({ ...state, user, error: null })),
  on(AuthActions.refreshUserFailure, (state, {error}) => ({ ...state, error, user: null }))

)
