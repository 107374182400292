import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {AuthUserService} from "../../@core/services/auth-user.service";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {catchError, map, mergeMap, of, switchMap} from "rxjs";
import * as AuthActions from './auth.actions';
import {ApiAuthService} from "../../@core/services/api/api-auth.service";
import {logout} from "./auth.actions";

@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions, private apiAuthService: ApiAuthService, private authService: AuthUserService, private router: Router, private store: Store) {
  }

  setToken$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.setToken),
    switchMap(action => {
      localStorage.setItem('token', action.token);
      return this.apiAuthService.getMe();
    }),
    map(user => AuthActions.setUser({ user })),
    catchError(error => of(AuthActions.userFailure({ error: error.error })))
  ));

  getUser$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.getUserFromToken),
    switchMap(() => this.apiAuthService.getMe()
      .pipe(
        map(user => AuthActions.setUser({ user })),
        catchError(error => {
          this.store.dispatch(logout());
          return of(AuthActions.userFailure({ error: error.error }))
        })
      )
    )
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logout),
    map(() => {
      this.authService.logout()
      return AuthActions.logoutSuccess();
    })
  ));

  refreshUser$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.refreshUser),
    mergeMap(() => this.apiAuthService.getMe()
      .pipe(
        map(user => AuthActions.refreshUserSuccess({ user })),
        catchError(error => of(AuthActions.refreshUserFailure( { error: error.error })))
      )
    )
  ));
}
