<div class="modal-header py-2">
		<h6 class="modal-title m-0">OTP Code</h6>
		<button
						type="button"
						class="btn-close"
						aria-label="Close"
						(click)="close()"
		></button>
</div>

<div class="modal-body text-center word-break-word">
		<p class="m-0">Please enter the OTP code sent to your email address</p>
		<div class="w-100 d-flex justify-content-center" [formGroup]="form">
				<mdb-form-control class="my-4" style="width: 150px">
						<input mdbInput required type="text" id="password" formControlName="bindingCode"
									 class="form-control"/>
						<label mdbLabel class="form-label" for="password">Code</label>
				</mdb-form-control>
		</div>
		<button type="button" class="btn btn-block btn-primary" loadingBtn [loadingState]="loading" (click)="click()">
				confirm
		</button>
</div>
