import {Injectable} from '@angular/core';
import {LoadingComponent} from '../components/modals/loading/loading.component';
import {MdbModalRef, MdbModalService} from 'mdb-angular-ui-kit/modal';
import {Subject} from 'rxjs';
import {TextComponent} from '../components/modals/text/text.component';
import {ConfirmModalComponent} from "../components/modals/confirm-modal/confirm-modal.component";
import {ViewJsonComponent} from "../components/modals/view-json/view-json.component";
import {OtpCodeComponent} from "../components/modals/otp-code/otp-code.component";
import {ILoginRes} from "../../interfaces/IAuth";
import {LensBroadcastComponent} from "../components/modals/lens-broadcast/lens-broadcast.component";
import {InfoEthValidatorComponent} from "../components/modals/info-eth-validator/info-eth-validator.component";
import {CreatePubkeyLedgerComponent} from "../components/modals/create-pubkey-ledger/create-pubkey-ledger.component";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private loadingModal!: MdbModalRef<LoadingComponent>;
  private textModal!: MdbModalRef<TextComponent>;
  private confirmModal!: MdbModalRef<ConfirmModalComponent>;
  private viewJsonModal!: MdbModalRef<ViewJsonComponent>;
  private otpCodeModal!: MdbModalRef<OtpCodeComponent>;
  private lensBroadcastModal!: MdbModalRef<LensBroadcastComponent>;
  private infoEthValidatorModal!: MdbModalRef<InfoEthValidatorComponent>;
  private createPubkeyLedgerModal!: MdbModalRef<CreatePubkeyLedgerComponent>;

  loadingText$: Subject<string | undefined> = new Subject();

  constructor(private modalService: MdbModalService) {
  }

  openLoadingModal(text?: string) {
    this.loadingText$ = new Subject();
    this.loadingModal = this.modalService.open(LoadingComponent, {
      modalClass: 'modal-dialog-centered modal-sm',
      ignoreBackdropClick: true,
      data: {text}
    });
  }

  updateLoadingModal(text?: string) {
    this.loadingText$.next(text);
  }

  openLoadingFullPageModal(text?: string) {
    this.loadingModal = this.modalService.open(LoadingComponent, {
      modalClass: ' modal-fullscreen loading-modal-fullpage',
      ignoreBackdropClick: true,
      animation: false,
      data: {text}
    });
  }

  closeLoadingModal() {
    this.loadingModal?.close();
    this.loadingText$.complete();
  }

  openModalText(title: string, body: string, status?: 'success' | 'error' | 'warning' | 'info' | 'default', size:'sm'|'md'|'lg' = 'sm', textAlign: 'center' | 'left' | 'right' = 'center') {
    const sizeClass = size === 'sm' ? 'modal-sm' : size === 'md' ? 'modal-md' : 'modal-lg';
    this.textModal = this.modalService.open(TextComponent, {
      modalClass: 'modal-dialog-centered '+ sizeClass,
      ignoreBackdropClick: true,
      data: {title, body, status, textAlign}
    });
    return this.textModal.onClose
  }

  openConfirmModal(title: string, body: string, size:'sm'|'md'|'lg' = 'sm') {
    const sizeClass = size === 'sm' ? 'modal-sm' : size === 'md' ? 'modal-md' : 'modal-lg';
    this.confirmModal = this.modalService.open(ConfirmModalComponent, {
      modalClass: 'modal-dialog-centered '+ sizeClass,
      ignoreBackdropClick: true,
      data: {title, body}
    });
    return this.confirmModal.onClose
  }

  openModalJson(title: string, json: Object, size:'sm'|'md'|'lg'|'xl' = 'sm') {
    const sizeClass = size === 'sm' ? 'modal-sm' : size === 'md' ? 'modal-md' : size === 'lg' ? 'modal-lg' : 'modal-xl';
    this.viewJsonModal = this.modalService.open(ViewJsonComponent, {
      modalClass: 'modal-dialog-centered '+ sizeClass,
      ignoreBackdropClick: true,
      data: {title, json}
    });
    return this.viewJsonModal.onClose
  }

  openOtpCodeModal(data: ILoginRes) {
    this.otpCodeModal = this.modalService.open(OtpCodeComponent, {
      modalClass: 'modal-dialog-centered modal-sm',
      ignoreBackdropClick: true,
      data
    });
    return this.otpCodeModal.onClose;
  }


	openModalLensBroadcast(title: string, data: Object, clearTx: string) {
		this.lensBroadcastModal = this.modalService.open(LensBroadcastComponent, {
			modalClass: 'modal-dialog-centered modal-lg',
			ignoreBackdropClick: true,
			data: {title, data, clearTx}
		});
		return this.lensBroadcastModal.onClose
	}

  openInfoEthValidator(title: string, data: Object) {
    this.infoEthValidatorModal = this.modalService.open(InfoEthValidatorComponent, {
      modalClass: 'modal-dialog-centered modal-md',
      ignoreBackdropClick: true,
      data: {title, data}
    });
    return this.infoEthValidatorModal.onClose
  }

  openCreatePubkeyLedger() {
    this.createPubkeyLedgerModal = this.modalService.open(CreatePubkeyLedgerComponent, {
      modalClass: 'modal-dialog-centered modal-md',
      ignoreBackdropClick: true,
      data: {}
    });
    return this.createPubkeyLedgerModal.onClose
  }


  closeOtpCodeModal() {
    this.otpCodeModal?.close();
  }

}
