<div class="modal-header alert-info">
    <h5 class="modal-title">{{title}}</h5>
    <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="close()"
    ></button>
</div>

<div class="modal-body word-break-word">
    <mdb-form-control>
        <textarea mdbInput class="form-control" id="textAreaExample" cols=50 rows=15 [innerHTML]="text"></textarea>
    </mdb-form-control>
</div>
