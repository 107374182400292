<ng-container *ngIf="loading; else loaded">
  <div class="d-flex justify-content-center align-items-center h-100">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-container>
<ng-template #loaded>
  <div class="d-flex flex-column h-100">
    <router-outlet></router-outlet>
    <div class="mt-auto" style="padding-left: 240px">
      <div id="footer" class="footer-viewer mt-3 py-3 px-5">
        <div class="d-flex">
          <img class="img-fluid" src="assets/OAM_licence+logo v02.svg" width="200">
          <div class="ms-auto text-center">
            <a href="mailto:info@colossus.digital">
              <button class="btn btn-social btn-floating me-2" routerLink="mailto:info@colossus.digital" type="button">
                <i class="far fa-envelope fa-lg"></i>
              </button>
            </a>
            <a href="https://colossus.digital" target="_website">
              <button class="btn btn-social btn-floating" type="button">
                <i class="fas fa-globe fa-lg"></i>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
