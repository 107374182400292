import { Component, OnInit } from '@angular/core';
import {MdbModalRef} from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent {
  title: string | null = null;
  body: string | null = null;
  status: 'success' | 'error' | 'warning' | 'info' | 'default' = 'default';
  textAlign: 'center' | 'left' | 'right' = 'center';

  constructor(private modalRef: MdbModalRef<TextComponent>) {
  }

  close(){
    this.modalRef.close();
  }



}
